import React from 'react';
import Box from '@magalu/stereo-ui/atoms/Box';
import { func, string, shape } from 'prop-types';
import AuthorizationTerm from '../AuthorizationTerm';
import { Button, Wrapper } from './ActionButtons.styles';
import Desktop from './Desktop';
var ActionButtons = function ActionButtons(_ref) {
  var authorizationTermText = _ref.authorizationTermText,
    handleAcquireServices = _ref.handleAcquireServices;
  return React.createElement(Wrapper, {
    "data-testid": "buttons-wrapper"
  }, React.createElement(Button, {
    color: "secondary",
    variation: "outline",
    uppercase: false,
    onClick: handleAcquireServices.continueWithoutServices,
    "data-testid": "dont-want-services"
  }, "Agora n\xE3o"), React.createElement(Box, {
    height: "20px"
  }), React.createElement(Button, {
    color: "secondary",
    uppercase: false,
    onClick: handleAcquireServices.continueAddingServices,
    "data-testid": "acquire-services"
  }, "Incluir prote\xE7\xE3o"), React.createElement(Box, {
    height: "20px"
  }), React.createElement(AuthorizationTerm, {
    authorizationTermText: authorizationTermText
  }));
};
ActionButtons.defaultProps = {
  authorizationTermText: 'Termos de autorização'
};
process.env.NODE_ENV !== "production" ? ActionButtons.propTypes = {
  authorizationTermText: string,
  handleAcquireServices: shape({
    continueAddingServices: func.isRequired,
    continueWithoutServices: func.isRequired
  }).isRequired
} : void 0;
ActionButtons.ssr = true;
ActionButtons.Desktop = Desktop;
export default ActionButtons;