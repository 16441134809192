import React from 'react';
import { func, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { VideoContainer, VideoLabel } from './ServiceInfo.styles';
var VideoButton = function VideoButton(_ref) {
  var handleClickEvent = _ref.handleClickEvent,
    label = _ref.label,
    labelBold = _ref.labelBold;
  return React.createElement(VideoContainer, {
    onClick: function onClick() {
      return handleClickEvent('video');
    },
    "data-testid": "video-trigger",
    pl: 3
  }, React.createElement(Icon, {
    name: "VideoCircle"
  }), React.createElement(VideoLabel, null, React.createElement("strong", null, labelBold), label));
};
process.env.NODE_ENV !== "production" ? VideoButton.propTypes = {
  handleClickEvent: func.isRequired,
  label: string.isRequired,
  labelBold: string.isRequired
} : void 0;
export default VideoButton;