import React from 'react';
import { shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
var Total = function Total(_ref) {
  var _total$title, _total$title2;
  var total = _ref.total;
  return React.createElement(Box, null, React.createElement(Text, {
    textAlign: "right",
    color: "primary.base"
  }, React.createElement(Text, {
    as: "span",
    color: "primary.base",
    fontWeight: "bold"
  }, "".concat(total == null ? void 0 : (_total$title = total.title) == null ? void 0 : _total$title.bold, " ")), total == null ? void 0 : (_total$title2 = total.title) == null ? void 0 : _total$title2.normal), React.createElement(Text, {
    textAlign: "right",
    mt: 2
  }, (total == null ? void 0 : total.subtitle) || ''));
};
Total.defaultProps = {
  total: {}
};
process.env.NODE_ENV !== "production" ? Total.propTypes = {
  total: shape({
    subtitle: string,
    title: shape({
      bold: string,
      normal: string
    })
  })
} : void 0;
export default Total;