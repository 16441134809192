import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import RawButton from '@magalu/stereo-ui/atoms/Button';
import { getMediaQuery } from '@magalu/stereo-ui-styles';
export var Button = styled(RawButton)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 12px;\n  font-size: 16px;\n  height: 48px;\n  width: 100%;\n  ", " {\n    width: 220px;\n  }\n"])), getMediaQuery('medium'));
export var Wrapper = styled(Box).attrs(function (props) {
  return {
    bg: 'background.white',
    height: '200px',
    props: props
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 25px 20px;\n  width: 100%;\n"])));