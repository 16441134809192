import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { arrayOf, number, objectOf, shape, string } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import useFinancialServicesNew from '../../../hooks/useFinancialServicesNew';
import ActionButtons from './ActionButtons';
import LegalInfo from './LegalInfo';
import PurchaseSummary from './PurchaseSummary';
import ServiceCard from './ServiceCard';
import { ActionButtonsWrapper, Box, ServiceCardsWrapper } from './FinancialServicesNew.styles';
var FinancialServicesNew = function FinancialServicesNew(props) {
  var _useFinancialServices = useFinancialServicesNew(props),
    actionButtonProps = _useFinancialServices.actionButtonProps,
    legalInfos = _useFinancialServices.legalInfos,
    purchaseSummary = _useFinancialServices.purchaseSummary,
    services = _useFinancialServices.services;
  return React.createElement(Box, {
    "data-testid": "services-container"
  }, React.createElement(ServiceCardsWrapper, null, services.map(function (service) {
    return React.createElement(ServiceCard, _extends({
      key: "service-".concat(service == null ? void 0 : service.code)
    }, service));
  })), React.createElement(PurchaseSummary, purchaseSummary), React.createElement(ServiceCardsWrapper, null, legalInfos.map(function (legalInfo) {
    return React.createElement(LegalInfo, _extends({
      key: legalInfo == null ? void 0 : legalInfo.key
    }, legalInfo));
  })), React.createElement(ActionButtonsWrapper, null, React.createElement(ActionButtons, actionButtonProps)));
};
FinancialServicesNew.defaultProps = {
  data: {
    product: {
      financialServices: [],
      installment: {}
    },
    serviceContent: {
      financialServicesContent: [],
      footerContent: []
    }
  },
  "static": {
    extraInfo: {},
    overrideDeclineMessages: {}
  }
};
process.env.NODE_ENV !== "production" ? FinancialServicesNew.propTypes = {
  data: shape({
    product: shape({
      financialServices: arrayOf(shape({
        description: string,
        id: string
      })),
      installment: shape({
        quantity: number
      })
    }),
    serviceContent: shape({
      financialServicesContent: arrayOf(shape({
        title: string
      })),
      footerContent: arrayOf(shape({
        content: string,
        title: string
      }))
    })
  }),
  "static": shape({
    extraInfo: shape({
      label: string,
      labelBold: string,
      title: string
    }),
    overrideDeclineMessages: objectOf(shape({
      description: string
    }))
  })
} : void 0;
FinancialServicesNew.ssr = true;
FinancialServicesNew.dataSource = {
  product: {
    query: 'ProductQuery'
  },
  serviceContent: {
    query: 'ServiceContentQuery'
  }
};
export default withLayoutProps(FinancialServicesNew);