import _extends from "@babel/runtime/helpers/esm/extends";
import getOfferPriceAndInstallments from './getOfferPriceAndInstallments';
import getOffersInput from './getOffersInput';
var getServiceInfos = function getServiceInfos(props) {
  var _service$offers$find;
  var selectedServicesIds = props.selectedServicesIds,
    service = props.service,
    servicesSelectedOfferId = props.servicesSelectedOfferId,
    installments = props.installments,
    onSelectedServicesIdsChanged = props.onSelectedServicesIdsChanged,
    onServicesSelectedOfferChange = props.onServicesSelectedOfferChange,
    publishServiceClickEvent = props.publishServiceClickEvent,
    publishServiceMoreDetails = props.publishServiceMoreDetails,
    publishServiceVideo = props.publishServiceVideo,
    publishKnowMore = props.publishKnowMore,
    publishServiceOfferChange = props.publishServiceOfferChange;
  var checked = selectedServicesIds.includes(service.id);
  return {
    checked: checked,
    disabled: selectedServicesIds.length === 1 && checked,
    extraInfo: _extends({}, service.extraInfo, {
      onModalOpen: function onModalOpen(_ref) {
        var open = _ref.open,
          modalType = _ref.modalType;
        if (open && modalType === 'agreement') {
          publishServiceMoreDetails({
            service: service.description
          });
        }
        if (open && modalType === 'video') {
          publishServiceVideo({
            service: service.description
          });
        }
      }
    }),
    key: service.id,
    offersInput: getOffersInput({
      onServicesSelectedOfferChange: onServicesSelectedOfferChange,
      publishServiceOfferChange: publishServiceOfferChange,
      service: service,
      serviceIdToOfferId: servicesSelectedOfferId
    }),
    onChange: function onChange(e) {
      publishServiceClickEvent({
        checked: e.target.checked,
        service: service
      });
      onSelectedServicesIdsChanged(service.id, e.target.checked);
    },
    onInfoClick: function onInfoClick() {
      publishKnowMore({
        service: service.description
      });
    },
    recommended: ((_service$offers$find = service.offers.find(function (offer) {
      return offer.id === servicesSelectedOfferId[service.id];
    })) == null ? void 0 : _service$offers$find.recommended) || false,
    selectedOfferPrice: getOfferPriceAndInstallments({
      installments: installments,
      service: service,
      serviceIdToOfferId: servicesSelectedOfferId
    }),
    title: service.title
  };
};
export default getServiceInfos;