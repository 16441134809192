import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import Box from '@magalu/stereo-ui/atoms/Box';
import styled from 'styled-components';
import { themeGet } from '@magalu/stereo-ui-styles';
import Text from '@magalu/stereo-ui/atoms/Text';
export var LeftInputText = styled(Text).attrs(function (props) {
  return props;
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n  font-weight: bold;\n  height: 48px;\n  padding: 16px 0px 0px 16px;\n  width: 24px;\n"])));
export var RightInputText = styled(Text).attrs(function (props) {
  return props;
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  font-weight: bold;\n  height: 48px;\n  padding: 16px 16px 0px 0px;\n  width: 24px;\n"])));
export var SelectedText = styled(Text)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  margin: 0px 8px;\n  padding-top: 16px;\n"])), themeGet('fontSizes.1'));
export var Wrapper = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  border: 1px solid ", ";\n  width: fit-content;\n"])), themeGet('colors.silver'));