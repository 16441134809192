import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useCallback, useState } from 'react';
var useServiceInfo = function useServiceInfo(props) {
  var benefit = props.benefit,
    title = props.title,
    agreementText = props.agreementText,
    label = props.label,
    labelBold = props.labelBold,
    videoUrl = props.videoUrl,
    onModalOpen = props.onModalOpen;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    agreementModalOpen = _useState4[0],
    setAgreementModalOpen = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    videoModalOpen = _useState6[0],
    setVideoModalOpen = _useState6[1];
  var handleClickEvent = useCallback(function (modalType) {
    setOpen(!open);
    if (onModalOpen) onModalOpen({
      modalType: modalType,
      open: !open
    });
    if (modalType === 'agreement') {
      setAgreementModalOpen(!agreementModalOpen);
      setVideoModalOpen(false);
      return;
    }
    setVideoModalOpen(!videoModalOpen);
    setAgreementModalOpen(false);
  }, [agreementModalOpen, videoModalOpen]);
  return {
    agreement: {
      title: title
    },
    benefit: benefit,
    dialog: {
      agreementModalOpen: agreementModalOpen,
      agreementText: agreementText,
      open: open,
      videoModalOpen: videoModalOpen,
      videoUrl: videoUrl
    },
    handleClickEvent: handleClickEvent,
    video: {
      label: label,
      labelBold: labelBold
    }
  };
};
export default useServiceInfo;