import React from 'react';
import { arrayOf, bool, func, number, objectOf, oneOfType, shape, string } from 'prop-types';
import { Box, Flex, Text } from '@magalu/stereo-ui/atoms';
import IncrementalInput from '../IncrementalInput';
import OfferPriceAndInstallments from '../OfferPriceAndInstallments';
import { Checkbox } from './ServiceCard.styles';
var Input = function Input(_ref) {
  var checked = _ref.checked,
    onChange = _ref.onChange,
    disabled = _ref.disabled,
    title = _ref.title,
    offersInput = _ref.offersInput,
    selectedOfferPrice = _ref.selectedOfferPrice;
  return React.createElement(Box, {
    pt: 3,
    pl: 3,
    pr: 3
  }, React.createElement(Checkbox, {
    checked: checked,
    onChange: onChange,
    disabled: disabled
  }, React.createElement(Text, {
    fontWeight: "bold"
  }, title)), React.createElement(Flex, {
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  }, React.createElement(IncrementalInput, offersInput), React.createElement(OfferPriceAndInstallments, selectedOfferPrice)));
};
var offersInputModel = process.env.NODE_ENV !== "production" ? shape({
  onChange: func,
  options: arrayOf(shape({
    text: string,
    value: oneOfType([number, string])
  })),
  style: objectOf(oneOfType([number, string])),
  value: oneOfType([number, string])
}) : {};
var selectedOfferPriceModel = process.env.NODE_ENV !== "production" ? shape({
  subtitle: string,
  title: shape({
    bold: string,
    text: string
  })
}) : {};
process.env.NODE_ENV !== "production" ? Input.propTypes = {
  checked: bool.isRequired,
  disabled: bool.isRequired,
  offersInput: offersInputModel.isRequired,
  onChange: func.isRequired,
  selectedOfferPrice: selectedOfferPriceModel.isRequired,
  title: string.isRequired
} : void 0;
export default Input;