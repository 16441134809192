import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { arrayOf, bool, func, number, objectOf, oneOfType, shape, string } from 'prop-types';
import { Box, Collapse, Flex, Text } from '@magalu/stereo-ui/atoms';
import ServiceInfo from '../ServiceInfo';
import Desktop from './Desktop';
import Input from './Input';
import Recommended from './Recommended';
import { ArrowIcon, HorizontalRule, Wrapper } from './ServiceCard.styles';
var ServiceCard = function ServiceCard(props) {
  var title = props.title,
    checked = props.checked,
    onChange = props.onChange,
    disabled = props.disabled,
    offersInput = props.offersInput,
    recommended = props.recommended,
    selectedOfferPrice = props.selectedOfferPrice,
    extraInfo = props.extraInfo,
    onInfoClick = props.onInfoClick;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var handleInfoClick = function handleInfoClick() {
    onInfoClick(!isOpen);
    setIsOpen(!isOpen);
  };
  return React.createElement(Wrapper, {
    "data-testid": "service-card"
  }, React.createElement(Recommended, {
    recommended: recommended
  }), React.createElement(Input, {
    checked: checked,
    onChange: onChange,
    disabled: disabled,
    title: title,
    offersInput: offersInput,
    selectedOfferPrice: selectedOfferPrice
  }), extraInfo && Object.keys(extraInfo).length > 0 && React.createElement(React.Fragment, null, React.createElement(HorizontalRule, null), React.createElement(Box, null, React.createElement(Collapse, {
    trigger: !isOpen
  }, React.createElement(ServiceInfo, extraInfo)), React.createElement(Flex, {
    alignItems: "center",
    pl: 3,
    pr: 3
  }, React.createElement(Text, {
    as: "span",
    fontSize: "12px",
    onClick: handleInfoClick,
    "data-testid": "open-service-info"
  }, "Saiba Mais"), React.createElement(ArrowIcon, {
    name: "Chevron".concat(isOpen ? 'Up' : 'Down')
  })))));
};
ServiceCard.Desktop = Desktop;
ServiceCard.defaultProps = {
  extraInfo: {},
  offersInput: {},
  onInfoClick: function onInfoClick() {},
  selectedOfferPrice: {}
};
var offersInputModel = process.env.NODE_ENV !== "production" ? shape({
  onChange: func,
  options: arrayOf(shape({
    text: string,
    value: oneOfType([number, string])
  })),
  style: objectOf(oneOfType([number, string])),
  value: oneOfType([number, string])
}) : {};
var selectedOfferPriceModel = process.env.NODE_ENV !== "production" ? shape({
  subtitle: string,
  title: shape({
    bold: string,
    text: string
  })
}) : {};
var propTypesModel = process.env.NODE_ENV !== "production" ? {
  checked: bool.isRequired,
  disabled: bool.isRequired,
  extraInfo: shape({}),
  offersInput: offersInputModel,
  onChange: func.isRequired,
  onInfoClick: func,
  recommended: bool.isRequired,
  selectedOfferPrice: selectedOfferPriceModel,
  title: string.isRequired
} : {};
process.env.NODE_ENV !== "production" ? ServiceCard.propTypes = propTypesModel : void 0;
export default ServiceCard;