import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { string } from 'prop-types';
import { ClickableText, DialogContainer, DialogContent, MainText } from './AuthorizationTerm.styles';
var AuthorizationTerm = function AuthorizationTerm(_ref) {
  var authorizationTermText = _ref.authorizationTermText;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showingTac = _useState2[0],
    setShowingTac = _useState2[1];
  return React.createElement(React.Fragment, null, React.createElement(MainText, {
    textAlign: "center"
  }, "Ao clicar em \"Incluir prote\xE7\xE3o\" voc\xEA concorda com os\xA0", React.createElement(ClickableText, {
    as: "span",
    fontWeight: "bold",
    "data-testid": "auth-term-label",
    onClick: function onClick() {
      return setShowingTac(true);
    }
  }, "Termos de autoriza\xE7\xE3o de cobran\xE7a")), React.createElement(DialogContainer, {
    "data-testid": "auth-term-dialog",
    onClickOutside: function onClickOutside() {
      return setShowingTac(false);
    },
    bg: "background.white",
    position: "center",
    type: "dialog",
    visible: showingTac,
    showClose: true
  }, React.createElement(DialogContent, {
    dangerouslySetInnerHTML: {
      __html: authorizationTermText
    }
  })));
};
AuthorizationTerm.defaultProps = {
  authorizationTermText: 'Termos de autorização'
};
process.env.NODE_ENV !== "production" ? AuthorizationTerm.propTypes = {
  authorizationTermText: string
} : void 0;
export default AuthorizationTerm;