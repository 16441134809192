import React from 'react';
import { bool } from 'prop-types';
import { RecommendedContainer, RecommendedText } from './ServiceCard.styles';
var Recommended = function Recommended(_ref) {
  var recommended = _ref.recommended;
  return React.createElement(RecommendedContainer, {
    recommended: recommended,
    "data-testid": "recommended-container"
  }, React.createElement(RecommendedText, null, "recomendado"));
};
process.env.NODE_ENV !== "production" ? Recommended.propTypes = {
  recommended: bool.isRequired
} : void 0;
export default Recommended;