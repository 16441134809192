import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';

var getOfferPriceAndInstallments = function getOfferPriceAndInstallments() {
  var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var title = {
    bold: '',
    text: ''
  };

  var subtitle = '';

  try {
    var service = input.service,
      serviceIdToOfferId = input.serviceIdToOfferId,
      installments = input.installments;
    var selectedOffer = service.offers.find(function (offer) {
      return offer.id === serviceIdToOfferId[service.id];
    });
    var newInstallments = !installments || installments <= 0 ? 1 : installments;
    title.text = "em at\xE9 ".concat(newInstallments, "x de ");
    title.bold = currencyFormatter({
      value: selectedOffer.price / newInstallments
    });
    subtitle = currencyFormatter({
      value: selectedOffer.price
    });
  } catch (_unused) {
    title.text = '';
    title.bold = '';
    subtitle = '';
  }
  return {
    subtitle: subtitle,
    title: title
  };
};
export default getOfferPriceAndInstallments;