import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { arrayOf, oneOfType, func, number, shape, string, objectOf } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { LeftInputText, RightInputText, SelectedText, Wrapper } from './IncrementalInput.styles';
import adaptProps from './adaptProps';
var IncrementalInput = function IncrementalInput(_ref) {
  var onChange = _ref.onChange,
    options = _ref.options,
    style = _ref.style,
    value = _ref.value;
  var _adaptProps = adaptProps({
      onChange: onChange,
      options: options,
      style: style,
      value: value
    }),
    minusColor = _adaptProps.minusColor,
    onMinus = _adaptProps.onMinus,
    plusColor = _adaptProps.plusColor,
    onPlus = _adaptProps.onPlus,
    selectedText = _adaptProps.selectedText;
  return React.createElement(Wrapper, {
    bg: "background.white",
    borderRadius: 3,
    style: _extends({}, style)
  }, React.createElement(Flex, null, React.createElement(LeftInputText, {
    role: "button",
    color: minusColor,
    onClick: onMinus,
    "data-testid": "minus-button",
    alt: "Diminuir vig\xEAncia"
  }, "-"), React.createElement(SelectedText, {
    as: "span",
    "data-testid": "selected-text"
  }, selectedText), React.createElement(RightInputText, {
    role: "button",
    color: plusColor,
    onClick: onPlus,
    "data-testid": "plus-button",
    alt: "Aumentar vig\xEAncia"
  }, "+")));
};
IncrementalInput.defaultProps = {
  onChange: function onChange() {},
  options: [],
  style: {},
  value: ''
};
process.env.NODE_ENV !== "production" ? IncrementalInput.propTypes = {
  onChange: func,
  options: arrayOf(shape({
    text: string,
    value: oneOfType([number, string])
  })),
  style: objectOf(oneOfType([number, string])),
  value: oneOfType([number, string])
} : void 0;
IncrementalInput.ssr = true;
export default IncrementalInput;