import React from 'react';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { func, shape } from 'prop-types';
import { Button } from './ActionButtons.styles';
var Desktop = function Desktop(_ref) {
  var handleAcquireServices = _ref.handleAcquireServices;
  return React.createElement(Flex, {
    justifyContent: "end",
    "data-testid": "buttons-wrapper"
  }, React.createElement(Button, {
    color: "secondary",
    variation: "outline",
    uppercase: false,
    onClick: handleAcquireServices.continueWithoutServices,
    "data-testid": "dont-want-services"
  }, "Agora n\xE3o"), React.createElement(Box, {
    width: "20px"
  }), React.createElement(Button, {
    color: "secondary",
    uppercase: false,
    onClick: handleAcquireServices.continueAddingServices,
    "data-testid": "acquire-services"
  }, "Incluir prote\xE7\xE3o"));
};
process.env.NODE_ENV !== "production" ? Desktop.propTypes = {
  handleAcquireServices: shape({
    continueAddingServices: func.isRequired,
    continueWithoutServices: func.isRequired
  }).isRequired
} : void 0;
export default Desktop;