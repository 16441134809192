var isAFunction = function isAFunction(elem) {
  return elem && typeof elem === 'function';
};

var adaptProps = function adaptProps() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$options = props.options,
    options = _props$options === void 0 ? [] : _props$options,
    value = props.value,
    onChange = props.onChange,
    _props$style = props.style,
    style = _props$style === void 0 ? {} : _props$style;
  var minusDisabled = true;
  var plusDisabled = true;
  var selectedText = '';
  var selectedOptionIdx = options.findIndex(function (option) {
    return option.value === value;
  });
  var onMinus = function onMinus() {
    if (minusDisabled || !isAFunction(onChange)) return;
    onChange(options[selectedOptionIdx - 1].value);
  };
  var onPlus = function onPlus() {
    if (plusDisabled || !isAFunction(onChange)) return;
    onChange(options[selectedOptionIdx + 1].value);
  };
  if (selectedOptionIdx >= 0) {
    minusDisabled = selectedOptionIdx === 0;
    plusDisabled = selectedOptionIdx === options.length - 1;
    selectedText = options[selectedOptionIdx].text || '';
  }
  var minusColor = minusDisabled ? 'text.base' : 'primary.base';
  var plusColor = plusDisabled ? 'text.base' : 'primary.base';
  return {
    minusColor: minusColor,
    onMinus: onMinus,
    onPlus: onPlus,
    plusColor: plusColor,
    selectedText: selectedText,
    style: style
  };
};
export default adaptProps;