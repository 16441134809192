import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import ResponsiveImage from '@magalu/stereo-ui/atoms/ResponsiveImage';
import StereoHorizontalRule from '@magalu/stereo-ui/atoms/HorizontalRule';
import StereoText from '@magalu/stereo-ui/atoms/Text';
export var HorizontalRule = styled(StereoHorizontalRule)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: '8px 0px';\n"])));
export var Image = styled(ResponsiveImage)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  max-width: 100%;\n  height: 79px;\n  width: 79px;\n"])));
export var ItemsText = styled(StereoText).attrs(function (props) {
  return _extends({
    fontSize: 14
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));