var getOffersInput = function getOffersInput(_ref) {
  var service = _ref.service,
    serviceIdToOfferId = _ref.serviceIdToOfferId,
    onServicesSelectedOfferChange = _ref.onServicesSelectedOfferChange,
    publishServiceOfferChange = _ref.publishServiceOfferChange;
  return {
    onChange: function onChange(offerId) {
      publishServiceOfferChange({
        newOfferId: offerId,
        service: service
      });
      onServicesSelectedOfferChange(service.id, offerId);
    },
    options: service.offers.map(function (offer) {
      return {
        text: offer.description.replace(service.description, '').trim(),
        value: offer.id
      };
    }),
    value: serviceIdToOfferId[service.id]
  };
};
export default getOffersInput;