import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
import styled from 'styled-components';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import HorizontalRule from '@magalu/stereo-ui/atoms/HorizontalRule';
import { Icon } from '@magalu/stereo-ui-icons';
import { getMediaQuery, themeGet } from '@magalu/stereo-ui-styles';
export var AgreementContent = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  line-height: ", ";\n"])), function (props) {
  return themeGet('lineHeights.display')(props);
});
export var AgreementLabel = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 13,
    lineHeight: '16px',
    paddingLeft: '16px',
    width: '144px'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));
export var BenefitContainer = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 10,
    textAlign: 'left'
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var BenefitText = styled(Text).attrs(function (props) {
  return _extends({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '16px'
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n"])), themeGet('fontSizes.1'));
export var DialogContainer = styled(Dialog)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-height: 80vh;\n  padding: ", "px ", "px;\n  ", " {\n    width: 85%;\n    padding: ", "px;\n  }\n"])), themeGet('space.3'), themeGet('space.2'), getMediaQuery('medium'), themeGet('space.3'));
export var IconCheck = styled(Icon).attrs(function (props) {
  return _extends({
    color: 'icons.like',
    height: 20,
    marginRight: 9,
    minHeight: 20,
    minWidth: 20,
    width: 20
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
export var RuleWithMargin = styled(HorizontalRule)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  margin: ", ";\n  width: ", ";\n"])), function (props) {
  return props.desktop ? '16px' : '16px 0';
}, function (props) {
  return props.desktop ? 'auto' : '100%';
});
export var VideoContainer = styled(Flex).attrs(function (props) {
  return _extends({
    flexDirection: 'row',
    width: 185
  }, props);
})(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));
export var VideoContent = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n\n  &::after {\n    padding-top: 56.25%;\n    display: block;\n    content: '';\n  }\n\n  > iframe {\n    position: absolute;\n    padding: 20px 0px 10px;\n    width: 100%;\n    height: 100%;\n  }\n"])));
export var VideoLabel = styled(Text).attrs(function (props) {
  return _extends({
    color: 'background.luContentPrimary',
    fontSize: 13,
    fontWeight: 'light',
    lineHeight: '19px',
    marginLeft: 10
  }, props);
})(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  margin-top: 3px;\n"])));