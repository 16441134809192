import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { Box, Flex, Text } from '@magalu/stereo-ui/atoms';
import { string, shape, func } from 'prop-types';
import { ArrowIcon, Button, DialogContainer, HorizontalRule, ModalContent } from './LegalInfo.styles';
var LegalInfo = function LegalInfo(_ref) {
  var title = _ref.title,
    btnTxt = _ref.btnTxt,
    modal = _ref.modal,
    onOpen = _ref.onOpen;
  var content = modal.content,
    modalTitle = modal.title;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var openCloseInfo = function openCloseInfo() {
    onOpen(!isOpen);
    setIsOpen(!isOpen);
  };
  return React.createElement(Box, {
    bg: "background.white",
    pb: 3,
    mb: 2,
    borderRadius: 8,
    color: "greyisBrown"
  }, React.createElement(Box, {
    pt: 4,
    pl: 3,
    pr: 3
  }, title), React.createElement(HorizontalRule, null), React.createElement(Flex, {
    alignItems: "center",
    pl: 3,
    pr: 3
  }, React.createElement(Text, {
    as: "span",
    fontSize: "12px",
    onClick: openCloseInfo
  }, btnTxt), React.createElement(ArrowIcon, {
    name: "ChevronRight"
  })), React.createElement(DialogContainer, {
    "data-testid": "dialog-container",
    onClickOutside: openCloseInfo,
    bg: "background.white",
    position: "center",
    type: "dialog",
    visible: isOpen,
    showClose: true,
    pl: 3,
    pr: 3
  }, React.createElement(Text, {
    fontWeight: "bold",
    lineHeight: "20px",
    mt: 3,
    mb: 3,
    textAlign: "center"
  }, modalTitle), React.createElement(ModalContent, null, content), React.createElement(Button, {
    color: "primary",
    mt: 3,
    uppercase: false,
    onClick: openCloseInfo
  }, "Entendi")));
};
LegalInfo.defaultProps = {
  btnTxt: '',
  modal: {
    content: '',
    title: ''
  },
  onOpen: function onOpen() {},
  title: ''
};
process.env.NODE_ENV !== "production" ? LegalInfo.propTypes = {
  btnTxt: string,
  modal: shape({
    content: string,
    title: string
  }),
  onOpen: func,
  title: string
} : void 0;
LegalInfo.ssr = true;
export default LegalInfo;