import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Benefits from './Benefits';
import Dialog from './Dialog';
import VideoButton from './VideoButton';
import { AgreementLabel, BenefitContainer, RuleWithMargin } from './ServiceInfo.styles';
import useServiceInfo from './useServiceInfo';
var Desktop = function Desktop() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useServiceInfo = useServiceInfo(props),
    agreement = _useServiceInfo.agreement,
    benefit = _useServiceInfo.benefit,
    dialog = _useServiceInfo.dialog,
    handleClickEvent = _useServiceInfo.handleClickEvent,
    video = _useServiceInfo.video;
  return React.createElement(React.Fragment, null, React.createElement(BenefitContainer, {
    flex: 1,
    pl: 4,
    pr: 4,
    mb: 2,
    mt: 2
  }, React.createElement(Benefits, {
    benefit: benefit
  })), (video.label || agreement.title) && React.createElement(RuleWithMargin, {
    desktop: true
  }), React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, video.label && React.createElement(VideoButton, _extends({
    handleClickEvent: handleClickEvent
  }, video)), agreement.title && React.createElement(AgreementLabel, {
    onClick: function onClick() {
      return handleClickEvent('agreement');
    },
    "data-testid": "agreement-trigger"
  }, agreement.title)), React.createElement(Dialog, _extends({
    handleClickEvent: handleClickEvent
  }, dialog)));
};
Desktop.defaultProps = {
  agreementText: '',
  label: '',
  labelBold: '',
  title: '',
  videoUrl: ''
};
process.env.NODE_ENV !== "production" ? Desktop.propTypes = {
  agreementText: string,
  benefit: arrayOf(shape({
    description: string
  })).isRequired,
  label: string,
  labelBold: string,
  title: string,
  videoUrl: string
} : void 0;
export default Desktop;