import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import Desktop from './Desktop';
import Items from './Items';
import Total from './Total';
import { Image } from './PurchaseSummary.styles';
var PurchaseSummary = function PurchaseSummary(props) {
  var productImage = props.productImage,
    items = props.items,
    total = props.total;
  return React.createElement(Box, {
    bg: "background.white",
    p: 3,
    "data-testid": "purchase-container"
  }, React.createElement(Flex, {
    justifyContent: "space-between",
    "data-testid": "purchase-header"
  }, React.createElement(Image, productImage), React.createElement(Items, {
    items: items
  })), React.createElement(Flex, {
    justifyContent: "space-between",
    mt: 2
  }, React.createElement(Text, {
    fontWeight: "bold"
  }, "Total:"), React.createElement(Total, {
    total: total
  })));
};
PurchaseSummary.Desktop = Desktop;
PurchaseSummary.defaultProps = {
  items: [],
  productImage: {
    alt: '',
    src: ''
  },
  total: {}
};
process.env.NODE_ENV !== "production" ? PurchaseSummary.propTypes = {
  items: arrayOf(shape({
    important: bool,
    subtitle: string,
    title: string
  })),
  productImage: shape({
    alt: string,
    src: string
  }),
  total: shape({
    subtitle: string,
    title: shape({
      bold: string,
      normal: string
    })
  })
} : void 0;
export default PurchaseSummary;