import React from 'react';
import { arrayOf, bool, func, number, objectOf, oneOfType, shape, string } from 'prop-types';
import ServiceInfo from '../ServiceInfo';
import Input from './Input';
import Recommended from './Recommended';
import { HorizontalRule, Wrapper } from './ServiceCard.styles';
var Desktop = function Desktop() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var title = props.title,
    checked = props.checked,
    onChange = props.onChange,
    disabled = props.disabled,
    _props$offersInput = props.offersInput,
    offersInput = _props$offersInput === void 0 ? {} : _props$offersInput,
    recommended = props.recommended,
    _props$selectedOfferP = props.selectedOfferPrice,
    selectedOfferPrice = _props$selectedOfferP === void 0 ? {} : _props$selectedOfferP,
    _props$extraInfo = props.extraInfo,
    extraInfo = _props$extraInfo === void 0 ? {} : _props$extraInfo;
  return React.createElement(Wrapper, {
    "data-testid": "service-card"
  }, React.createElement(Recommended, {
    recommended: recommended
  }), React.createElement(Input, {
    recommended: recommended,
    checked: checked,
    onChange: onChange,
    disabled: disabled,
    title: title,
    offersInput: offersInput,
    selectedOfferPrice: selectedOfferPrice
  }), React.createElement(HorizontalRule, null), React.createElement(ServiceInfo.Desktop, extraInfo));
};
Desktop.defaultProps = {
  extraInfo: {},
  offersInput: {},
  selectedOfferPrice: {}
};
var offersInputModel = process.env.NODE_ENV !== "production" ? shape({
  onChange: func,
  options: arrayOf(shape({
    text: string,
    value: oneOfType([number, string])
  })),
  style: objectOf(oneOfType([number, string])),
  value: oneOfType([number, string])
}) : {};
var selectedOfferPriceModel = process.env.NODE_ENV !== "production" ? shape({
  subtitle: string,
  title: shape({
    bold: string,
    text: string
  })
}) : {};
var propTypesModel = process.env.NODE_ENV !== "production" ? {
  checked: bool.isRequired,
  disabled: bool.isRequired,
  extraInfo: shape({}),
  offersInput: offersInputModel,
  onChange: func.isRequired,
  recommended: bool.isRequired,
  selectedOfferPrice: selectedOfferPriceModel,
  title: string.isRequired
} : {};
process.env.NODE_ENV !== "production" ? Desktop.propTypes = propTypesModel : void 0;
export default Desktop;