import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { slug } from '@magalu/mixer-utils';
import { BenefitText, IconCheck } from './ServiceInfo.styles';
var Benefits = function Benefits(_ref) {
  var benefit = _ref.benefit;
  return React.createElement(React.Fragment, null, benefit == null ? void 0 : benefit.map(function (_ref2) {
    var description = _ref2.description;
    return React.createElement(BenefitText, {
      key: slug(description),
      "data-testid": "benefit-container"
    }, React.createElement(IconCheck, {
      name: "Done"
    }), description);
  }));
};
process.env.NODE_ENV !== "production" ? Benefits.propTypes = {
  benefit: arrayOf(shape({
    description: string
  })).isRequired
} : void 0;
export default Benefits;