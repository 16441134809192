import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Items from './Items';
import Total from './Total';
var Desktop = function Desktop(props) {
  var _total$title;
  var items = props.items,
    total = props.total;
  var adaptedTotal = _extends({}, total || {}, {
    title: _extends({}, (total == null ? void 0 : total.title) || {}, {
      bold: "Subtotal: ".concat((total == null ? void 0 : (_total$title = total.title) == null ? void 0 : _total$title.bold) || '')
    })
  });
  return React.createElement(Flex, {
    justifyContent: "space-between",
    mt: 2,
    "data-testid": "purchase-container"
  }, React.createElement(Items, {
    items: items,
    textEllipsisMaxLength: 40,
    dividers: false
  }), React.createElement(Total, {
    total: adaptedTotal
  }));
};
Desktop.defaultProps = {
  items: [],
  total: {}
};
process.env.NODE_ENV !== "production" ? Desktop.propTypes = {
  items: arrayOf(shape({
    important: bool,
    subtitle: string,
    title: string
  })),
  total: shape({
    subtitle: string,
    title: shape({
      bold: string,
      normal: string
    })
  })
} : void 0;
export default Desktop;