import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import Text from '@magalu/stereo-ui/atoms/Text';
import ActionButtons from '../ActionButtons';
import AuthorizationTerm from '../AuthorizationTerm';
import PurchaseSummary from '../PurchaseSummary/PurchaseSummary';
import { Box } from './PurchaseSummaryAndActionButtons.styles';
var PurchaseSummaryAndActionButtons = function PurchaseSummaryAndActionButtons(props) {
  var actionButtonProps = props.actionButtonProps,
    purchaseSummary = props.purchaseSummary;
  return React.createElement(React.Fragment, null, React.createElement(Box, {
    bg: "background.white",
    borderRadius: 8
  }, React.createElement(Text, {
    fontWeight: "bold"
  }, "Resumo"), React.createElement(PurchaseSummary.Desktop, purchaseSummary), React.createElement(ActionButtons.Desktop, actionButtonProps)), React.createElement(Box, {
    mt: 3,
    mb: 2
  }, React.createElement(AuthorizationTerm, {
    authorizationTermText: actionButtonProps.authorizationTermText
  })));
};
PurchaseSummaryAndActionButtons.defaultProps = {
  actionButtonProps: {
    authorizationTermText: 'Termos de autorização'
  }
};
process.env.NODE_ENV !== "production" ? PurchaseSummaryAndActionButtons.propTypes = {
  actionButtonProps: {
    authorizationTermText: string,
    handleAcquireServices: shape({
      continueAddingServices: func.isRequired,
      continueWithoutServices: func.isRequired
    }).isRequired
  },
  purchaseSummary: {
    items: arrayOf(shape({
      important: bool,
      subtitle: string,
      title: string
    })),
    productImage: shape({
      alt: string,
      src: string
    }),
    total: shape({
      subtitle: string,
      title: shape({
        bold: string,
        normal: string
      })
    })
  }.isRequired
} : void 0;
export default PurchaseSummaryAndActionButtons;