import React from 'react';
import { arrayOf, bool, shape, string, number } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { textEllipsis } from '@magalu/mixer-utils';
import { HorizontalRule, ItemsText } from './PurchaseSummary.styles';
var Items = function Items(_ref) {
  var items = _ref.items,
    _ref$textEllipsisMaxL = _ref.textEllipsisMaxLength,
    textEllipsisMaxLength = _ref$textEllipsisMaxL === void 0 ? 20 : _ref$textEllipsisMaxL,
    _ref$dividers = _ref.dividers,
    dividers = _ref$dividers === void 0 ? true : _ref$dividers;
  return React.createElement(Box, null, items.map(function (item, idx) {
    return React.createElement(Box, {
      key: "summary-item-".concat(item.title)
    }, React.createElement(Flex, {
      gap: 4,
      justifyContent: "space-between"
    }, React.createElement(ItemsText, {
      fontWeight: item.important ? 'bold' : 'normal',
      mt: 2,
      mb: 2
    }, textEllipsis(item.title, textEllipsisMaxLength)), React.createElement(ItemsText, {
      fontWeight: "bold",
      mt: 2,
      mb: 2
    }, item.subtitle)), dividers && idx !== (items == null ? void 0 : items.length) - 1 && React.createElement(HorizontalRule, null));
  }));
};
Items.defaultProps = {
  dividers: true,
  items: [],
  textEllipsisMaxLength: 20
};
process.env.NODE_ENV !== "production" ? Items.propTypes = {
  dividers: bool,
  items: arrayOf(shape({
    important: bool,
    subtitle: string,
    title: string
  })),
  textEllipsisMaxLength: number
} : void 0;
export default Items;