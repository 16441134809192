import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';
var getPurchaseSummary = function getPurchaseSummary(props) {
  var _product$price, _product$price2, _product$price3;
  var selectedServicesIds = props.selectedServicesIds,
    services = props.services,
    servicesSelectedOfferId = props.servicesSelectedOfferId,
    product = props.product,
    installments = props.installments,
    installmentConditions = props.installmentConditions;
  var selectedServices = services.filter(function (service) {
    return selectedServicesIds.includes(service.id);
  });
  var getServiceOffer = function getServiceOffer(service) {
    return service.offers.find(function (offer) {
      return offer.id === servicesSelectedOfferId[service.id];
    });
  };
  var items = selectedServices.map(function (service) {
    var selectedOffer = getServiceOffer(service);
    return {
      important: true,
      subtitle: currencyFormatter({
        value: selectedOffer.price
      }),
      title: selectedOffer.description
    };
  });
  var allSelectedServicesCost = selectedServices.reduce(function (acc, service) {
    var selectedOffer = getServiceOffer(service);
    return acc + selectedOffer.price;
  }, 0);
  var bestPrice = (product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.bestPrice) || 0;
  var totalBestPrice = (bestPrice || 0) + allSelectedServicesCost;
  var priceInInstallments = (((product == null ? void 0 : (_product$price2 = product.price) == null ? void 0 : _product$price2.fullPrice) || 0) + allSelectedServicesCost) / installments;
  return {
    items: [{
      important: false,
      subtitle: currencyFormatter({
        value: bestPrice
      }),
      title: (product == null ? void 0 : product.title) || ''
    }].concat(_toConsumableArray(items)),
    productImage: {
      alt: (product == null ? void 0 : product.title) || '',
      src: (product == null ? void 0 : product.image) || ''
    },
    total: {
      subtitle: "ou ".concat(installments, "x de ").concat(currencyFormatter({
        value: priceInInstallments
      }), " ").concat(installmentConditions),
      title: {
        bold: currencyFormatter({
          value: totalBestPrice
        }),
        normal: (product == null ? void 0 : (_product$price3 = product.price) == null ? void 0 : _product$price3.paymentMethodDescription) || ''
      }
    }
  };
};
export default getPurchaseSummary;