import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { arrayOf, number, objectOf, shape, string } from 'prop-types';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import withLayoutProps from '../../../hocs/withLayoutProps';
import useFinancialServicesNew from '../../../hooks/useFinancialServicesNew';
import ServiceCard from '../FinancialServicesNew/ServiceCard';
import PurchaseSummaryAndActionButtons from '../FinancialServicesNew/PurchaseSummaryAndActionButtons';
import { ServicesGrid, Title } from './FinancialServicesNewDesktop.styles';
var FinancialServicesNewDesktop = function FinancialServicesNewDesktop(props) {
  var _ref = useFinancialServicesNew(props) || {},
    actionButtonProps = _ref.actionButtonProps,
    purchaseSummary = _ref.purchaseSummary,
    services = _ref.services;
  return React.createElement(Grid, {
    "data-testid": "services-container-desktop"
  }, React.createElement(Title, {
    "data-testid": "financial-services-title"
  }, "Incluir mais prote\xE7\xE3o"), React.createElement(ServicesGrid, {
    nCards: services.length
  }, services.map(function (service) {
    return React.createElement(ServiceCard.Desktop, _extends({
      key: "service-".concat(service == null ? void 0 : service.code)
    }, service));
  })), React.createElement(PurchaseSummaryAndActionButtons, {
    purchaseSummary: purchaseSummary,
    actionButtonProps: actionButtonProps
  }));
};
FinancialServicesNewDesktop.defaultProps = {
  data: {
    product: {
      financialServices: [],
      installment: {}
    },
    serviceContent: {
      financialServicesContent: [],
      footerContent: []
    }
  },
  "static": {
    extraInfo: {},
    overrideDeclineMessages: {}
  }
};
process.env.NODE_ENV !== "production" ? FinancialServicesNewDesktop.propTypes = {
  data: shape({
    product: shape({
      financialServices: arrayOf(shape({
        description: string,
        id: string
      })),
      installment: shape({
        quantity: number
      })
    }),
    serviceContent: shape({
      financialServicesContent: arrayOf(shape({
        title: string
      })),
      footerContent: arrayOf(shape({
        title: string
      }))
    })
  }),
  "static": shape({
    extraInfo: shape({
      label: string,
      labelBold: string,
      title: string
    }),
    overrideDeclineMessages: objectOf(shape({
      description: string
    }))
  })
} : void 0;
FinancialServicesNewDesktop.ssr = true;
FinancialServicesNewDesktop.dataSource = {
  product: {
    query: 'ProductQuery'
  },
  serviceContent: {
    query: 'ServiceContentQuery'
  }
};
export default withLayoutProps(FinancialServicesNewDesktop);