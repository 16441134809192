import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from 'styled-components';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import { Icon } from '@magalu/stereo-ui-icons';
import StereoHorizontalRule from '@magalu/stereo-ui/atoms/HorizontalRule';
import { getMediaQuery, themeGet } from '@magalu/stereo-ui-styles';
import RawButton from '@magalu/stereo-ui/atoms/Button';
import Text from '@magalu/stereo-ui/atoms/Text';
export var ArrowIcon = styled(Icon).attrs(function (props) {
  return _extends({
    position: 'relative'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));
export var Button = styled(RawButton)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border-radius: 12px;\n  font-size: 16px;\n  height: 48px;\n  width: 100%;\n"])));
export var DialogContainer = styled(Dialog)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-height: 80vh;\n  padding: ", "px ", "px;\n  ", " {\n    width: 85%;\n    padding: ", "px;\n  }\n"])), themeGet('space.3'), themeGet('space.2'), getMediaQuery('medium'), themeGet('space.3'));
export var HorizontalRule = styled(StereoHorizontalRule).attrs(function (props) {
  return _extends({}, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin: 12px 0px;\n  width: 100%;\n"])));
export var ModalContent = styled(Text).attrs(function (props) {
  return _extends({
    lineHeight: '20px',
    pl: 3,
    pr: 3,
    textAlign: 'justify'
  }, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  word-break: break-word;\n"])));